<template>
  <div v-if="!cookie && show">
    <v-row class="footer"
      >{{ $t('cookie') }}{{ cookie }}
      <img
        @click="disableBanner"
        src="/exit-button.png"
        width="35px"
        height="35px"
        class="pointer close-img"
    /></v-row>
    <div class="overlay"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true
    }
  },
  computed: {
    cookie() {
      return this.$auth.$storage.getCookie('cookies_consent')
    }
  },
  methods: {
    disableBanner(str, phone) {
      this.$auth.$storage.setCookie('cookies_consent', true)
      this.show = false
    }
  }
}
</script>
<style lang="scss">
.footer {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0px;
  height: 77px;
  width: 90%;
  opacity: 0.9;
  border-radius: 25px 25px 0 0;
  background: linear-gradient(270deg, #ffc53e 0%, #faa337 100%);
  color: #ffffff;
  font-family: 'Biennale Medium It';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.23px;
  line-height: 77px;
  padding-left: 33px;
  @include breakpoint(xs) {
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    top: 0px;
    bottom: 0px;
    border-radius: 10px;
    background: none;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 100px 0 rgba(24, 0, 77, 0.4);
    color: #18004d;
    font-family: Biennale;
    font-size: 16px;
    letter-spacing: 0.13px;
    line-height: 23px;
    font-style: normal;
    z-index: 11;
    opacity: 1;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    padding-right: 60px;
    height: 84px;

    img {
      content: url('/exit-mobile.png');
      width: 30px;
      height: 30px;
      margin-top: -20px !important;
    }
  }
}
.footer > .close-img {
  margin-top: 20px;
  right: 0px;
  display: block;
  position: absolute;
  margin-right: 21px;
}
.overlay {
  @include breakpoint(xs) {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 120%;
    z-index: 10;
    opacity: 0.29;
    background-color: #18004d;
  }
}

.rtl-layout {
  .footer {
    padding-right: 33px;
    font-family: Heebo;
    font-size: 18px;
    letter-spacing: 0.23px;
    line-height: 77px;
    text-align: right;
    font-weight: 600;
    @include breakpoint(xs) {
      line-height: 25px;
      font-weight: 400;
    }

    .close-img {
      margin-top: 20px;
      left: 0px;
      display: block;
      position: absolute;
      margin-left: 21px;
      right: auto;
    }
  }
}
</style>
