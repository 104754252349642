<template>
  <v-container :fluid="true">
    <v-row class="justify-center">
      <v-col class="text-col" xs="12" sm="12" md="5" lg="5">
        <div class="index-title">
          <span class="unbold"> {{ $t('index.title') }}</span>
          <span class="bold title-bold">{{ $t('index.titleBold') }}</span>
        </div>
        <v-row class="text-row mt-7">
          <v-col cols="2" class="text-center px-0">
            <img class="mt-eng-8" src="agreement-icon.png" />
          </v-col>
          <v-col cols="10" class="text-1 px-0">
            {{ $t('index.onlyObstacle') }}
            <span class="bold"> {{ $t('index.reachThem') }}</span>
            <span class="heb-display"> {{ $t('index.text1') }}</span>
          </v-col>
        </v-row>
        <v-row class="text-row">
          <v-col cols="2" class="text-center px-0">
            <img src="cost-icon.png" />
          </v-col>
          <v-col styclass="mt-eng-8" cols="10" class="text-1 px-0">
            {{ $t('index.theyCan') }}
            <span class="bold">{{ $t('index.longCostly') }}</span>
          </v-col>
        </v-row>
        <v-row class="text-row">
          <v-col cols="2" class="text-center px-0">
            <img class="mt-eng-8" src="sensitive-info.png" />
          </v-col>
          <v-col cols="10" class="text-1 px-0">
            <span class="eng-display"> {{ $t('index.and') }}</span>
            <span class="bold">{{ $t('index.sensitiveInformation') }}</span>
            {{ $t('index.withoutGuarantee') }}</v-col
          >
        </v-row>
        <v-row class="text-row">
          <v-col cols="2" class="text-center px-0">
            <img style="margin-top: 3px;" src="time-icon.png" />
          </v-col>
          <v-col cols="10" class="text-1 px-0">
            {{ $t('index.GetRealLabPlace') }}
            <span class="bold">{{ $t('index.immediateAgreement') }}</span>
            <span class="eng-display">{{ $t('index.withoutAny') }}</span></v-col
          >
        </v-row>
        <div class="btn-container mt-7">
          <v-btn
            :to="localePath({ path: '/register' })"
            class="btn-main-join"
            rounded
            >{{ $t('index.joinUs') }}</v-btn
          >
          <v-btn
            :to="localePath({ path: '/about' })"
            class="btn-main-learn ml-5"
            rounded
            >{{ $t('index.learnMore') }}</v-btn
          >
        </div>
      </v-col>
      <v-col class="video-col" xs="12" sm="12" md="7" lg="7">
        <div id="videoDiv" ref="videoDiv" class="video-div">
          <div id="videoBg" ref="videoBg" class="video-bg"></div>
          <video
            id="videoPlayer"
            ref="videoPlayer"
            v-show="video"
            class="video1"
            controls="controls"
          >
            <source src="GetRealLab.mp4" type="video/mp4" />
          </video>
          <div
            id="videoOverlay"
            ref="videoOverlay"
            v-show="!video"
            class="videoOverlay"
          >
            <img @click="play" src="/play-button.png" class="pointer" />
            <div class="text-div">
              <div class="text-1">At GetReal Lab there are</div>
              <div class="text-2">
                {{ $t('index.noNegotiations') }}
                <span style="color: #FAA337">{{
                  $t('index.meaningfulOffers')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <Cookies />
  </v-container>
</template>

<script>
import Cookies from '@/components/Cookies.vue'

export default {
  auth: false,
  components: {
    Cookies
  },
  data() {
    return {
      video: false
    }
  },
  mounted() {
    if (window.innerWidth <= 700) {
      const width = window.innerWidth - 30
      const height = (width / 647) * 365

      this.$refs.videoBg.style.height = height + 'px'
      this.$refs.videoOverlay.style.width = width + 'px'
      this.$refs.videoOverlay.style.height = height + 'px'
      this.$refs.videoPlayer.style.width = width + 'px'
      this.$refs.videoOverlay.style.height = height + 'px'
      this.$refs.videoDiv.style.height = height + 'px'
    }
  },
  methods: {
    play() {
      this.video = true
      this.$refs.videoPlayer.play()
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Biennale';
  src: local('Biennale'),
    url(~assets/fonts/Biennale-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Biennale SemiBold';
  src: local('Biennale SemiBold'),
    url(~assets/fonts/Biennale-SemiBold.otf) format('opentype');
}
@font-face {
  font-family: 'Biennale Medium';
  src: local('Biennale Medium'),
    url(~assets/fonts/Biennale-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Biennale Medium It';
  src: local('Biennale Medium It'),
    url(~assets/fonts/Biennale-Medium-It.otf) format('opentype');
}
@font-face {
  font-family: 'Biennale Bold';
  src: local('Biennale Bold'),
    url(~assets/fonts/Biennale-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Almoni';
  src: local('Almoni'),
    url(~assets/fonts/almoni-dl-aaa-bold.otf) format('opentype');
}
@font-face {
  font-family: 'Heebo';
  src: local('Heebo'), url(~assets/fonts/Heebo-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Heebo Bold';
  src: local('Heebo Bold'), url(~assets/fonts/Heebo-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Heebo Medium';
  src: local('Heebo Medium'),
    url(~assets/fonts/Heebo-Medium.ttf) format('truetype');
}

.container {
  background: #ffffff;
  background-image: url(/home-shape.svg);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  @include breakpoint(xs) {
    background: white;
    padding-top: 0px;
  }
}
.v-content__wrap {
  background-image: url(/home-shape.svg);
}
.index-title {
  color: #18004d;
  font-family: 'Biennale Medium';
  font-size: 26px;
  letter-spacing: 0.21px;
  line-height: 36px;
  margin-left: 49px;
  margin-top: 45px;
  @include breakpoint(xs) {
    text-align: center;
    margin-left: 0px;
  }
}
.bold {
  font-weight: 600;
  font-family: 'Biennale SemiBold';
}
.unbold {
  font-weight: 500;
}
.btn-main-join {
  height: 37px;
  width: 132px;
  border-radius: 24.5px;
  background: linear-gradient(90deg, #5e14fe 0%, #844aff 100%);
  box-shadow: 3px 6px 6px 0 #f2f2f2;
  color: #ffffff;
  font-family: 'Biennale SemiBold';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.13px;
  line-height: 20px;
  text-transform: none;
}
.btn-main-learn {
  height: 37px;
  width: 132px;
  border-radius: 24.5px;
  background: linear-gradient(270deg, #ffc53e 0%, #faa337 100%);
  box-shadow: 3px 6px 6px 0 #f2f2f2;
  color: #ffffff;
  font-family: 'Biennale SemiBold';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.13px;
  line-height: 20px;
  text-align: center;
  text-transform: none;
}
.video-div {
  width: 647px;
  height: 365px;
  position: relative;
  float: right;
  margin-right: 53px;
  margin-top: 62px;
  @include breakpoint(xs) {
    margin-right: 0px;
    max-width: 100%;
    margin-top: 33px;
  }
  @include breakpoint(md) {
    margin-right: 0px;
    width: 500px;
    height: 282px;
  }
  @include breakpoint(lg) {
    width: 647px;
    height: 365px;
    margin-right: 53px;
  }
}
.video1 {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  outline: none;
  border-radius: 15px;
  @include breakpoint(xs) {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}
.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 15px;
  @include breakpoint(xs) {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    box-shadow: 7px 7px 20px 0 #e6e6e6;
  }

  img {
    @include breakpoint(xs) {
      width: 40px;
    }
  }
}
.video-bg {
  position: absolute;
  top: -33px;
  left: -34px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: linear-gradient(270deg, #ffc53e 0%, #faa337 100%);
  @include breakpoint(xs) {
    width: 100%;
    left: 0px;
    border-radius: 0px;
  }
}
.text-1 {
  color: #18004d;
  font-family: Biennale;
  font-size: 16px;
  letter-spacing: 0.13px;
  line-height: 23px;
}
.text-2 {
  color: #18004d;
  font-family: 'Biennale';
  font-size: 18px;
  letter-spacing: 0.21px;
  line-height: 28px;
  font-style: italic;
  font-weight: 600;
}
.text-div {
  position: absolute;
  text-align: center;
  bottom: 35px;
  @include breakpoint(xs) {
    bottom: 0px;
  }
}
.text-row {
  width: 400px;
  margin-left: 100px;
  @include breakpoint(xs) {
    text-align: left;
    margin-left: 0px;
    width: 100%;
  }
}
.btn-container {
  margin-left: 115px;
  @include breakpoint(xs) {
    text-align: center;
    margin-left: 0px;
    margin-bottom: 30px;
  }
}
.video-col {
  @include breakpoint(xs) {
    order: 1;
    padding: 0px;
  }
}
.text-col {
  @include breakpoint(xs) {
    order: 2;
  }
}
.title-bold {
  font-family: 'Biennale Bold';

  @include breakpoint(xs) {
    display: block;
  }
}
.heb-display {
  display: none;
}
.mt-eng-8 {
  margin-top: 8px;
}

.rtl-layout {
  .container {
    background-image: url(/bg-shape-heb.svg);
    background-position: -10px;
    @include breakpoint(xs) {
      background: white;
      padding-top: 0px;
    }
  }
  .video-div {
    float: left;
    margin-left: 120px;
    margin-top: 80px;
    @include breakpoint(xs) {
      margin-left: 0px;
      max-width: 100%;
      margin-top: 33px;
    }
    @include breakpoint(md) {
      margin-left: 0px;
      width: 500px;
      height: 282px;
    }
    @include breakpoint(lg) {
      width: 647px;
      height: 365px;
      margin-left: 53px;
    }
  }
  .videoOverlay {
    box-shadow: 7px 7px 20px 0 #e6e6e6;
  }
  .text-div .text-1 {
    display: none;
  }
  .text-2 {
    font-family: Heebo;
    font-size: 16px;
    letter-spacing: 0.21px;
    line-height: 28px;
    text-align: center;
  }
  .index-title {
    font-family: Heebo;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.22px;
    line-height: 41px;
    text-align: right;
    @include breakpoint(xs) {
      text-align: center;
      margin-right: 0px !important;
    }
  }
  .btn-main-learn,
  .btn-main-join {
    font-family: 'Heebo Bold';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.13px;
    line-height: 21px;
    text-align: center;
  }
  .text-1 {
    font-family: Heebo;
    font-size: 18px;
    letter-spacing: 0.14px;
    line-height: 25px;
    text-align: right;
  }
  .heb-display {
    display: inline;
  }
  .text-row {
    width: 500px;
    margin-right: 70px;
    @include breakpoint(xs) {
      text-align: right;
      margin-right: 10px;
      width: 90%;
    }
  }
  .eng-display {
    display: none;
  }
  .btn-container {
    margin-right: 105px;
    @include breakpoint(xs) {
      text-align: center;
      margin-right: 0px;
      margin-bottom: 30px;
    }
  }
  .btn-main-learn {
    margin-right: 20px;
  }
  .index-title {
    margin-right: 95px;
  }
  .mt-eng-8 {
    margin-top: 0px;
  }
  .title-bold {
    font-family: 'Heebo Bold' !important;
  }
  .bold {
    font-family: 'Heebo Medium';
    font-weight: 300;
  }
  .videoOverlay {
    .text-2 {
      font-family: 'Heebo Bold';
    }
  }
}
</style>
